import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Affiliate } from "./components/Affiliate_Dashboard/Affiliate";
// import { Security } from "./components/Security/Security";
// import { Docs } from "./components/Docs/Docs";
import React, { Suspense, lazy } from "react";
const Affiliate = React.lazy(()=> import('./components/Affiliate_Dashboard/Affiliate'))
const Security = React.lazy(()=> import('./components/Security/Security'))
const Docs = React.lazy(()=> import('./components/Docs/Docs'))
const Home = React.lazy(() =>
  import("./components/home-component/home-content/Home")
);
const Blogs = React.lazy(() => import("./components/Blog/Blogs"));
const BlogDetail = React.lazy(() => import("./components/Blog/BlogDetails"));
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const ContactPage = React.lazy(() =>
  import("./components/home-component/ContactPage/ContactPage")
);
const Pricing = React.lazy(() => import("./components/Pricing/Pricing"));
const SignUp = React.lazy(() => import("./components/Auth/SignUp"));
const Login = React.lazy(() => import("./components/Auth/Login"));
const Assistant = React.lazy(() => import("./components/Dashboard/Assitant"));
const ProfileSetting = lazy(() =>
  import("./components/Dashboard/Profile/ProfileSetting")
);
const Feedback = React.lazy(() =>
  import("./components/Dashboard/Profile/Feedback")
);
function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/affiliate" element={<Affiliate />} />
          <Route path="/assistant" element={<Assistant />} />
          <Route path="/contact-page" element={<ContactPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/security" element={<Security />} />
          <Route path="/document" element={<Docs />} />
          <Route path="/profileSetting" element={<ProfileSetting />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
